import React, { useEffect, useState } from "react";
import { GoInfo } from "react-icons/go";
import { BiPlus, BiChevronRight, BiChevronLeft } from "react-icons/bi";
import { FiUsers } from "react-icons/fi";
import { RiSurveyLine } from "react-icons/ri";
import { LuUser } from "react-icons/lu";
import { Calendar, Menu, Dropdown, Table, Tooltip } from "antd";
import { MdOutlineProductionQuantityLimits } from "react-icons/md";
import {
  Card,
  Col,
  Row,
  Space,
  Statistic,
  Typography,
  Descriptions,
  Image,
  Button,
  message,
  Progress,
  Form,
  Select,
  Input,
  Modal,
  DatePicker,
  TimePicker,
  Checkbox,
  FloatButton,
  Switch,
} from "antd";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  PlusOutlined,
  BankOutlined,
  DownOutlined,
  UpOutlined,
  EditOutlined,
  DeleteOutlined,
  CloseOutlined,
  CalendarOutlined,
  FieldTimeOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import APICall from "../../services/apiservices";
import CountUp from "react-countup";
import moment from "moment";

const { Option } = Select;
const { Title } = Typography;
const timeSlotsForCalendar = Array.from(
  { length: 24 },
  (_, i) => `${i + 1}:00`
);

const Dashboard = () => {
  const [form] = Form.useForm();
  const [isEditing, setIsEditing] = useState(false);
  const [emptySlots, setEmptySlots] = useState([]);
  const [currentDate, setCurrentDate] = useState(moment());
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [dateRanges, setDateRanges] = useState([]);
  const [hoveredTimeSlot, setHoveredTimeSlot] = useState(null);
  const [hoveredSlot, setHoveredSlot] = useState(null);
  const [bookings, setBookings] = useState([]);
  const [dateRangeIndex, setDateRangeIndex] = useState(0);
  const [filteredBookings, setFilteredBookings] = useState([]);
  const [showWeekend, setShowWeekend] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [statsData, setStatsData] = useState();
  const [loading, setIsLoading] = useState(false);
  const [showFirstDiv, setShowFirstDiv] = useState(true);
  const [isThirdDivVisible, setIsThirdDivVisible] = useState(false);
  const [companyData, setCompanyData] = useState();
  const [categoriesDropdownVisible, setCategoriesDropdownVisible] =
    useState(false);
  const [meetingRoomsChecked, setMeetingRoomsChecked] = useState(false);
  const [isMainModalVisible, setIsMainModalVisible] = useState(false);
  const [isConferenceModalVisible, setIsConferenceModalVisible] =
    useState(false);
  const [calendarMode, setCalendarMode] = useState("month");
  const [selectedMenuItem, setSelectedMenuItem] = useState("Week");
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [open, setOpen] = useState(true);
  const navaigate = useNavigate();
  const [isAddModalVisible, setAddModalVisible] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedTimeFrom, setSelectedTimeFrom] = useState("");
  const [selectedTimeTo, setSelectedTimeTo] = useState("");
  const [hoveredRow, setHoveredRow] = useState(null);
  const [hoverIndex, setHoverIndex] = useState(null);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [showBookingModal, setShowBookingModal] = useState(false);
  const [hoveredButton, setHoveredButton] = useState(null);
  const [selectedDate, setSelectedDate] = useState(
    moment().format("DD MMM YYYY")
  );
  const [currentStartDate, setCurrentStartDate] = useState(
    moment().startOf("isoWeek")
  );
  const today = new Date();
  const [startDateAgenda, setStartDateAgenda] = useState(new Date());

  const onPanelChangeLeft = (value, mode) => {
    console.log(value.format("YYYY-MM-DD"), mode);
  };

  const formatter = (value) => <CountUp end={value} separator="," />;

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const getAdminStats = async () => {
    setIsLoading(true);
    try {
      const response = await APICall("/admin/stats", {}, 1);
      setStatsData(response.data.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      message.error(error.response.data.message);
    }
  };

  const switchStyle = {
    margin: 16,
    backgroundColor: open ? "rgb(38, 182, 137)" : "grey",
  };

  const getCompanyData = async () => {
    try {
      const res = await APICall(`/company/649bff0f87a35d7bececa3d8`, {}, 1);
      setCompanyData(res.data);
    } catch (error) {}
  };

  useEffect(() => {
    getAdminStats();
    getCompanyData();
  }, []);

  const onPanelChange = (value, mode) => {
    console.log(value.format("YYYY-MM-DD"), mode);
    setCalendarMode(mode);
  };

  const handleLeftArrowClick = () => {
    const newDate = moment(currentDate).subtract(1, "days");
    setCurrentDate(newDate);
  };

  const handleRightArrowClick = () => {
    const newDate = moment(currentDate).add(1, "days");
    setCurrentDate(newDate);
  };

  const onFinish = async (values) => {
    console.log(values, "values");
    const fromDateTime = new Date(values.fromDate);
    const toDateTime = new Date(values.toDate);

    const fromDateString = fromDateTime.toLocaleDateString("en-US", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
    const toDateString = toDateTime.toLocaleDateString("en-US", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });

    const fromTime = fromDateTime.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hourCycle: "h23",
    });
    const toTime = toDateTime.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hourCycle: "h23",
    });

    try {
      const payload = {
        ...values,
        fromDate: fromDateString,
        toDate: toDateString,
        fromTime: fromTime,
        toTime: toTime,
      };
      console.log(payload, "payload");
      const response = isEditing
        ? await APICall(`/conferencebooking/${selectedBooking._id}`, payload, 3)
        : await APICall("/conferencebooking", payload, 2);

      if (response.status === 200) {
        message.success(
          isEditing ? "Booking updated successfully" : "Booking successful"
        );
        form.resetFields();
        handleAddModalOk();
        setIsModalVisible(false);
        setShowBookingModal(false);
        setIsConferenceModalVisible(false);
        setAddModalVisible(false);
        fetchBookings();
      }
    } catch (error) {
      console.error("Failed to book:", error);
    }
  };

  const fetchBookings = async () => {
    try {
      const response = await APICall("/conferencebooking", {}, 1);
      if (response.status === 200) {
        const fetchedBookings = response.data.documents;
        setBookings(fetchedBookings);
        const calculatedEmptySlots = findEmptySlots(
          fetchedBookings,
          currentDate
        );
        setEmptySlots(calculatedEmptySlots);
      }
    } catch (error) {
      console.error("Failed to fetch bookings:", error);
      message.error("Failed to fetch bookings");
    }
  };

  useEffect(() => {
    fetchBookings();
  }, []);

  const getBookingsForDate = (date) => {
    return bookings.filter((booking) =>
      moment(booking.fromDate).isSame(date, "day")
    );
  };

  const getTimeSlotBooking = (bookings, time) => {
    const slotTime = moment(time, "HH:mm");
    return bookings.filter((booking) => {
      const bookingStart = moment(booking.fromTime, "HH:mm");
      const bookingEnd = moment(booking.toTime, "HH:mm");
      return slotTime.isBetween(bookingStart, bookingEnd, null, "[)");
    });
  };

  const formatDate = (date) => {
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [month, day, year].join("-");
  };

  const formatDateForSelectedDate = (date) => {
    const options = { day: "numeric", month: "long", year: "numeric" };
    return new Intl.DateTimeFormat("en-US", options).format(new Date(date));
  };

  const handleBookingClick = (booking) => {
    setSelectedBooking(booking);
    setShowBookingModal(true);
  };

  const removeAmPm = (time) => {
    return time.replace(/(AM|PM)/i, "").trim();
  };

  const dateCellRender = (value) => {
    const formattedDate = formatDate(value.toDate());

    const dailyBookings = bookings.filter(
      (booking) => formatDate(new Date(booking.fromDate)) === formattedDate
    );

    const handleEmptyDateClick = (date) => {
      // Open the modal here if the date is empty
      setSelectedDate(date.format("DD MMM YYYY"));
      setIsConferenceModalVisible(true);
    };

    return dailyBookings.length > 0 ? (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          cursor: "default",
          padding: "0 33px",
        }}
      >
        {dailyBookings.map((booking, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              // margin: "0px 70px 0px 0px",
              textAlign: "center",
              cursor: "pointer",
            }}
            onClick={() => handleBookingClick(booking)}
          >
            <div
              style={{
                width: "8px",
                height: "8px",
                backgroundColor: "#f84f88",
                borderRadius: "50%",
                margin: "6px 0px 0px -30px",
              }}
            ></div>
            <div
              style={{
                fontSize: "12px",
                color: "#999",
                margin: "0px 0px 0px 5px",
              }}
            >
              {removeAmPm(booking.fromTime)}
            </div>
            <div
              style={{
                fontSize: "10px",
                color: "#666",
                margin: "2px 0px 0px 3px ",
              }}
            >
              {booking.bookingName}
            </div>
          </div>
        ))}
      </div>
    ) : (
      <div
        style={{ height: "100%", cursor: "pointer" }}
        onClick={() => handleEmptyDateClick(value)}
      >
        {/* Empty cell content, you can add a plus icon or leave it empty */}
      </div>
    );
  };

  const parseDateTime = (date, time) => {
    const dateTimeString = `${date} ${time}`;
    const parsedDate = new Date(dateTimeString);
    if (isNaN(parsedDate)) {
      throw new RangeError(`Invalid date value: ${dateTimeString}`);
    }
    return parsedDate;
  };

  const formatDateTime = (fromDate, fromTime, toDate, toTime) => {
    try {
      const fromDateTime = parseDateTime(fromDate, fromTime);
      const toDateTime = parseDateTime(toDate, toTime);

      const dayFormatter = new Intl.DateTimeFormat("en-US", {
        weekday: "long",
      });
      const dateFormatter = new Intl.DateTimeFormat("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
      const timeFormatter = new Intl.DateTimeFormat("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true, // Ensure 12-hour format with AM/PM
      });

      const day = dayFormatter.format(fromDateTime); // e.g., "Saturday"
      const date = dateFormatter.format(fromDateTime); // e.g., "15.06.2024"
      const fromTimeFormatted = removeAmPm(timeFormatter.format(fromDateTime)); // e.g., "02:00 AM"
      const toTimeFormatted = removeAmPm(timeFormatter.format(toDateTime)); // e.g., "03:00 AM"

      return `${day}, ${date} ${fromTimeFormatted} - ${toTimeFormatted}`;
    } catch (error) {
      console.error("Error formatting date:", error);
      return "Invalid date";
    }
  };

  const formattedDateTime = selectedBooking
    ? formatDateTime(
        selectedBooking.fromDate,
        selectedBooking.fromTime,
        selectedBooking.toDate,
        selectedBooking.toTime
      )
    : "";

  useEffect(() => {
    if (selectedBooking) {
      setSelectedDate(moment(selectedBooking.fromDate).format("DD MMM YYYY"));
      setSelectedTimeFrom(
        moment(selectedBooking.fromTime, "HH:mm").format("hh:mm A")
      );
      setSelectedTimeTo(
        moment(selectedBooking.toTime, "HH:mm").format("hh:mm A")
      );

      form.setFieldsValue({
        spot: selectedBooking.spot || "Conference Room",
        repeatBooking: selectedBooking.repeatBooking || "Do not repeat",
        fromDate: `${moment(selectedBooking.fromDate).format(
          "DD MMM YYYY"
        )} ${moment(selectedBooking.fromTime, "HH:mm").format("hh:mm A")}`,
        toDate: `${moment(selectedBooking.toDate).format(
          "DD MMM YYYY"
        )} ${moment(selectedBooking.toTime, "HH:mm").format("hh:mm A")}`,
        bookingName: selectedBooking.bookingName || "",
        note: selectedBooking.note || "",
        guestsCanJoin: selectedBooking.guestsCanJoin || false,
      });
    }
  }, [selectedBooking, form]);

  const handleEdit = (bookingId) => {
    const booking = bookings.find((b) => b._id === bookingId);
    if (booking) {
      setSelectedBooking(booking);
      setShowBookingModal(false);
      setAddModalVisible(true);
      setIsEditing(true);

      const fromDate = moment(
        `${booking.fromDate} ${booking.fromTime}`,
        "MMM DD, YYYY hh:mm A"
      );
      const toDate = moment(
        `${booking.toDate} ${booking.toTime}`,
        "MMM DD, YYYY hh:mm A"
      );

      form.setFieldsValue({
        spot: booking.spot,
        repeatBooking: booking.repeatBooking,
        fromDate: fromDate.isValid() ? fromDate : null,
        toDate: toDate.isValid() ? toDate : null,
        bookingName: booking.bookingName,
        note: booking.note,
        guestsCanJoin: booking.guestsCanJoin,
      });
    }
  };

  const handlePrev = () => {
    setCurrentStartDate(currentStartDate.clone().subtract(7, "days"));
  };

  const handleNext = () => {
    setCurrentStartDate(currentStartDate.clone().add(7, "days"));
  };

  const handleMenuClick = ({ key }) => {
    setSelectedMenuItem(key);
    setDropdownVisible(false);
  };

  const toggleCategoriesDropdown = () => {
    setCategoriesDropdownVisible(!categoriesDropdownVisible);
  };

  const handleCheckboxChange = (e) => {
    setMeetingRoomsChecked(e.target.checked);
  };

  const onChange = (checked) => {
    setOpen(checked);
  };

  const showConferenceModal = () => {
    setIsConferenceModalVisible(true);
    setIsModalVisible(false);
  };

  const handleCancelConference = () => {
    setIsConferenceModalVisible(false);
    // setIsModalVisible(true);
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="Day">Day</Menu.Item>
      <Menu.Item key="Week">Week</Menu.Item>
      <Menu.Item key="Month">Month</Menu.Item>
      <Menu.Item key="Agenda">Agenda</Menu.Item>
    </Menu>
  );

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  useEffect(() => {
    generateDateRanges(currentStartDate);
    setCurrentDate(moment().startOf("day").toDate());
    setSelectedDate(moment().format("DD MMM YYYY"));
  }, [currentStartDate]);

  const generateDateRanges = (startDate) => {
    const newDateRanges = [];
    let start = moment(startDate).startOf("isoWeek");

    for (let i = 0; i < 10; i++) {
      let end = moment(start).endOf("isoWeek");

      newDateRanges.push({
        start: start.format("MMM D"),
        end: end.format("MMM D"),
        year: start.year(),
      });

      start = moment(end).add(1, "day").startOf("isoWeek");
    }

    setDateRanges(newDateRanges);
    setDateRangeIndex(0);
  };

  const handleNextDateRange = () => {
    if (dateRangeIndex < dateRanges.length - 1) {
      setDateRangeIndex((prevIndex) => prevIndex + 1);
    } else {
      const lastEndDate = moment(
        dateRanges[dateRanges.length - 1].end,
        "MMM D"
      ).add(1, "day");
      const nextStartDate = lastEndDate.clone().startOf("isoWeek");
      const nextEndDate = lastEndDate.clone().endOf("isoWeek");

      const newDateRange = {
        start: nextStartDate.format("MMM D"),
        end: nextEndDate.format("MMM D"),
        year: nextStartDate.year(), // Store the year for reference
      };

      setDateRanges([...dateRanges, newDateRange]);
      setDateRangeIndex(dateRanges.length);
    }
  };

  const handlePreviousDateRange = () => {
    if (dateRangeIndex > 0) {
      setDateRangeIndex((prevIndex) => prevIndex - 1);
    } else {
      const firstStartDate = moment(dateRanges[0].start, "MMM D").subtract(
        1,
        "day"
      );
      const prevEndDate = firstStartDate.clone().endOf("isoWeek");
      const prevStartDate = firstStartDate.clone().startOf("isoWeek");

      const newDateRange = {
        start: prevStartDate.format("MMM D"),
        end: prevEndDate.format("MMM D"),
        year: prevStartDate.year(),
      };

      setDateRanges((prevRanges) => [newDateRange, ...prevRanges]);
    }
  };

  const generateWeekDates = () => {
    if (dateRanges.length === 0) return [];

    const startOfWeek = moment(
      dateRanges[dateRangeIndex].start,
      "MMM D"
    ).startOf("isoWeek");
    return [...Array(7)].map((_, i) => startOfWeek.clone().add(i, "days"));
  };

  const generateTimeSlots = () => {
    return [...Array(24)].map((_, i) => `${i}:00`);
  };

  const handleAddModalOk = () => {
    setAddModalVisible(false);
    setSelectedBooking(null);
    setIsEditing(false);
  };

  const handleAddModalCancel = () => {
    setAddModalVisible(false);
    setSelectedBooking(null);
    setIsEditing(false);
  };

  const conferenceModalShow = () => {
    form.resetFields();
    setAddModalVisible(false);
    setIsConferenceModalVisible(true);
  };

  const showFirstModal = () => {
    form.resetFields();
    setIsConferenceModalVisible(false);
    setIsModalVisible(true);
  };

  const handleAddTimeSlot = (time) => {
    const bookingsForSelectedDate = getBookingsForDate(selectedDate);
    const bookingsForTimeSlot = getTimeSlotBooking(
      bookingsForSelectedDate,
      time
    );

    let startTime = moment(time, "HH:mm");

    let endTime = startTime.clone().add(1, "hour");

    if (bookingsForTimeSlot.length > 0) {
      const maxEndTime = bookingsForTimeSlot.reduce((latest, booking) => {
        const bookingEnd = moment(booking.toTime, "HH:mm");
        return bookingEnd.isAfter(latest) ? bookingEnd : latest;
      }, startTime);

      startTime = maxEndTime;
      endTime = startTime.clone().add(1, "hour");
    }

    setSelectedTimeFrom(startTime.format("HH:mm"));
    setSelectedTimeTo(endTime.format("HH:mm"));

    form.resetFields();
    setIsConferenceModalVisible(false);
    setAddModalVisible(startTime.format("HH:mm"));
  };

  const handleDateClick = (date, index) => {
    setSelectedIndex(index);
    setSelectedDate(date.format("DD MMM YYYY"));
    setSelectedTimeFrom(date.format("HH:mm A"));
    setSelectedTimeTo(date.format("HH:mm A"));
    setCurrentDate(date.toDate());
  };

  const getCurrentWeekDates = () => {
    const today = new Date();
    const dayOfWeek = today.getDay();

    const startOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate() - dayOfWeek);
    startOfWeek.setHours(0, 0, 0, 0);

    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);
    endOfWeek.setHours(23, 59, 59, 999);

    return { startOfWeek, endOfWeek };
  };

  const { startOfWeek, endOfWeek } = getCurrentWeekDates();
  const [startDate, setStartDate] = useState(startOfWeek);
  const [endDate, setEndDate] = useState(endOfWeek);

  const handlePreviousAgenda = () => {
    setStartDateAgenda(
      new Date(startDateAgenda.setDate(startDateAgenda.getDate() - 7))
    );
  };

  const handleNextAgenda = () => {
    setStartDateAgenda(
      new Date(startDateAgenda.setDate(startDateAgenda.getDate() + 7))
    );
  };

  const weekDays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const getWeekDates = (start) => {
    const dates = [];
    const current = new Date(start);
    const dayOfWeek = current.getDay(); // 0 (Sunday) - 6 (Saturday)
    const mondayOffset = (dayOfWeek === 0 ? -6 : 1) - dayOfWeek; // Calculate the offset to get to Monday
    current.setDate(current.getDate() + mondayOffset); // Move to the Monday of the current week

    for (let i = 0; i < 7; i++) {
      dates.push(new Date(current));
      current.setDate(current.getDate() + 1);
    }
    return dates;
  };

  const weekDates = getWeekDates(startDateAgenda);

  useEffect(() => {
    const style = document.createElement("style");
    style.innerHTML = `
      .ant-modal-close {
        color: black !important; /* Change to your desired color */
      }
      .ant-modal-close:hover {
        color: black !important; /* Change color on hover if needed */
      }
      .ant-modal-close-x {
        font-size: 16px !important; /* Adjust size if needed */
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, [startDate, endDate]);

  const handleDelete = async () => {
    try {
      const response = await APICall(
        `/conferencebooking/${selectedBooking._id}`,
        {},
        4
      );
      if (response.status === 200) {
        message.success("Booking deleted successfully");
        setShowDeleteModal(false);
        setShowBookingModal(false);
        fetchBookings();
      } else {
        message.error("Failed to delete the booking");
      }
    } catch (error) {
      console.error("Failed to delete:", error);
      message.error("An error occurred while deleting the booking");
    }
  };

  const showDeleteConfirmation = () => {
    setShowDeleteModal(true);
  };

  const calculateBookingStyle = (booking) => {
    const fromTime = moment(booking.fromTime, "HH:mm");
    const toTime = moment(booking.toTime, "HH:mm");
    const duration = toTime.diff(fromTime, "minutes") / 60;

    const slotHeight = 42;
    const startHour = fromTime.hour();
    const startMinutes = fromTime.minutes();

    const top = -33 + startHour * slotHeight + (startMinutes / 60) * slotHeight;
    const height = duration * slotHeight;
    const left = 145;

    return {
      top,
      height,
      left,
    };
  };

  const findEmptySlots = (bookings, currentDate) => {
    const emptySlots = [];
    const sortedBookings = bookings
      .filter((booking) =>
        moment(booking.fromDate).isSame(moment(currentDate), "day")
      )
      .sort((a, b) =>
        moment(a.fromTime, "HH:mm").diff(moment(b.fromTime, "HH:mm"))
      );

    const startOfDay = moment(currentDate).startOf("day");
    const endOfDay = moment(currentDate).endOf("day");

    if (
      sortedBookings.length > 0 &&
      moment(sortedBookings[0].fromTime, "HH:mm").isAfter(startOfDay)
    ) {
      emptySlots.push({
        startTime: startOfDay,
        endTime: moment(sortedBookings[0].fromTime, "HH:mm"),
      });
    }

    for (let i = 0; i < sortedBookings.length - 1; i++) {
      const currentBookingEnd = moment(sortedBookings[i].toTime, "HH:mm");
      const nextBookingStart = moment(sortedBookings[i + 1].fromTime, "HH:mm");

      if (currentBookingEnd.isBefore(nextBookingStart)) {
        emptySlots.push({
          startTime: currentBookingEnd,
          endTime: nextBookingStart,
        });
      }
    }

    if (
      sortedBookings.length > 0 &&
      moment(
        sortedBookings[sortedBookings.length - 1].toTime,
        "HH:mm"
      ).isBefore(endOfDay)
    ) {
      emptySlots.push({
        startTime: moment(
          sortedBookings[sortedBookings.length - 1].toTime,
          "HH:mm"
        ),
        endTime: endOfDay,
      });
    }

    return emptySlots;
  };

  const handleSlotClick = (time, endTime) => {
    const startMoment = moment(time, "HH:mm");
    const endMoment = moment(endTime, "HH:mm");

    const relevantBookings = bookings.filter((booking) => {
      const bookingDate = moment(booking.fromDate);
      const bookingStartTime = moment(booking.fromTime, "HH:mm");
      const bookingEndTime = moment(booking.toTime, "HH:mm");

      return (
        bookingDate.isSame(moment(currentDate), "day") &&
        ((bookingStartTime.isBefore(startMoment) &&
          bookingEndTime.isAfter(startMoment)) || // Booking starts before and ends after the start time
          (bookingStartTime.isBefore(endMoment) &&
            bookingEndTime.isAfter(endMoment))) // Booking starts before and ends after the end time
      );
    });

    let adjustedStartTime = startMoment;
    if (relevantBookings.length > 0) {
      // Get the latest end time from the relevant bookings
      const latestEndTime = moment.max(
        relevantBookings.map((booking) => moment(booking.toTime, "HH:mm"))
      );

      // Set the adjusted start time to the latest end time of the bookings
      adjustedStartTime = latestEndTime;
    }
    const formattedStartTime = adjustedStartTime.format("HH:mm");
    const formattedEndTime = adjustedStartTime
      .clone()
      .add(1, "hour")
      .format("HH:mm");

    // Update state and open modal
    form.resetFields();
    setSelectedBooking(null);
    setSelectedTimeFrom(formattedStartTime);
    setSelectedTimeTo(formattedEndTime);
    handlePlusIconClick(formattedStartTime, formattedEndTime);
    setHoveredSlot(null);
  };

  const handleRowClick = (booking) => {
    setSelectedBooking(booking);
    setShowBookingModal(true);
  };

  const formatTime = (timeString) => {
    const [time, period] = timeString.split(" ");
    const [hours, minutes] = time.split(":");

    let formattedHours = parseInt(hours, 10);

    if (period === "PM" && formattedHours !== 12) {
      formattedHours += 12;
    }
    if (period === "AM" && formattedHours === 12) {
      formattedHours = 0;
    }

    const formattedTime = `${String(formattedHours).padStart(
      2,
      "0"
    )}:${minutes}`;
    return formattedTime;
  };

  const handlePlusIconClick = (time) => {
    const formattedTime = moment(time, "H:mm").format("HH:mm");

    const selectedBooking = bookings.find(
      (b) =>
        moment(b.fromDate).isSame(moment(currentDate), "day") &&
        moment(b.fromTime, "HH:mm").format("HH:mm") === formattedTime
    );

    if (selectedBooking) {
      setSelectedDate(moment(selectedBooking.fromDate).format("DD MMM YYYY"));
      setSelectedTimeFrom(
        moment(selectedBooking.toTime, "hh:mm A").format("HH:mm")
      );
      const nextSlotTime = moment(selectedBooking.toTime, "HH:mm")
        .add(30, "minutes") // Assuming you want to start the new slot 30 minutes after the existing booking ends
        .format("HH:mm");

      setSelectedTimeTo(nextSlotTime);
    } else {
      setSelectedDate(moment(currentDate).format("DD MMM YYYY"));
      setSelectedTimeFrom(formattedTime);

      const defaultEndTime = moment(formattedTime, "HH:mm")
        .add(1, "hour")
        .format("HH:mm");
      setSelectedTimeTo(defaultEndTime);
    }

    setAddModalVisible(true);
  };

  const handlePlusIconClickAdd = (date) => {
    const selectedDate = new Date(date);
    const formattedDate = moment(selectedDate).format("DD MMM YYYY");

    const formattedTime = "00:00"; // Default start time if no bookings exist

    const existingBookings = bookings.filter(
      (booking) => new Date(booking.fromDate).toDateString() === date
    );

    if (existingBookings.length > 0) {
      const latestEndTime = existingBookings.reduce((latest, booking) => {
        const endTime = moment(booking.toTime, "HH:mm");
        return endTime.isAfter(latest) ? endTime : latest;
      }, moment("00:00", "HH:mm"));

      const nextStartTime = latestEndTime.add(30, "minutes").format("HH:mm");
      setSelectedTimeFrom(nextStartTime);
    } else {
      setSelectedTimeFrom(formattedTime);
    }

    // Set the end time to 1 hour after the start time
    const endTime = moment(selectedTimeFrom, "HH:mm")
      .add(1, "hour")
      .format("HH:mm");
    setSelectedTimeTo(endTime);

    // Update the modal state
    setSelectedDate(formattedDate);
    setIsConferenceModalVisible(true);
  };

  const handleConferenceModalOpen = () => {
    setIsConferenceModalVisible(true);
    setAddModalVisible(false);
  };

  useEffect(() => {
    const todayIndex = generateWeekDates().findIndex((date) =>
      date.isSame(moment(), "day")
    );
    setSelectedIndex(todayIndex);
  }, [generateWeekDates]);

  useEffect(() => {
    const formattedSelectedDate = new Date(selectedDate)
      .toISOString()
      .split("T")[0];
    const bookedTimes = bookings
      .filter(
        (booking) =>
          new Date(booking.fromDate).toISOString().split("T")[0] ===
          formattedSelectedDate
      )
      .map((booking) => formatTime(booking.fromTime));
  }, [selectedDate, bookings]);

  const formatTimeForBooking = (time) => {
    let [hour, minute] = time.split(":");
    hour = parseInt(hour, 10);
    return `${hour}:${minute}`;
  };

  const generateTimeSlotsForBooking = () => {
    const times = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const time = `${hour.toString().padStart(2, "0")}:${minute
          .toString()
          .padStart(2, "0")}`;
        times.push(formatTimeForBooking(time));
      }
    }
    return times;
  };

  const timeSlots = generateTimeSlotsForBooking();

  const handleTimeSelect = (time, type) => {
    if (type === "from") {
      setSelectedTimeFrom(time);
      form.setFieldsValue({ fromDate: `${selectedDate} ${time}` });

      const fromIndex = timeSlots.indexOf(time);
      const toTime = timeSlots[fromIndex + 1];

      if (!isToTimeBlocked(toTime)) {
        setSelectedTimeTo(toTime);
        form.setFieldsValue({ toDate: `${selectedDate} ${toTime}` });
      } else {
        setSelectedTimeTo(null);
        form.setFieldsValue({ toDate: null });
      }
    } else if (type === "to") {
      setSelectedTimeTo(time);
      form.setFieldsValue({ toDate: `${selectedDate} ${time}` });
    }
  };

  const isTimeBooked = (time) => {
    const formattedSelectedDate = new Date(selectedDate)
      .toISOString()
      .split("T")[0];
    const formattedTime = formatTime(time);
    return bookings.some((booking) => {
      const bookingDate = new Date(booking.fromDate)
        .toISOString()
        .split("T")[0];
      const fromTime = formatTime(booking.fromTime);
      const toTime = formatTime(booking.toTime);

      // Check if the time falls within the booked range
      return (
        bookingDate === formattedSelectedDate &&
        formattedTime >= fromTime &&
        formattedTime < toTime
      );
    });
  };

  const isFromTimeBlocked = (time) => {
    const formattedSelectedDate = new Date(selectedDate)
      .toISOString()
      .split("T")[0];
    const formattedTime = formatTime(time);

    return bookings.some((booking) => {
      const bookingDate = new Date(booking.fromDate)
        .toISOString()
        .split("T")[0];
      const fromTime = formatTime(booking.fromTime);
      const toTime = formatTime(booking.toTime);

      // Check if the time falls within the booked range
      return (
        bookingDate === formattedSelectedDate &&
        formattedTime >= fromTime &&
        formattedTime < toTime
      );
    });
  };

  const isToTimeBlocked = (time) => {
    const formattedSelectedDate = new Date(selectedDate)
      .toISOString()
      .split("T")[0];
    const formattedTime = formatTime(time);

    // Check if the selected "From" time exists and block times before it
    if (selectedTimeFrom) {
      const selectedFromTime = formatTime(selectedTimeFrom);
      if (formattedTime <= selectedFromTime) {
        return true;
      }
    }

    return bookings.some((booking) => {
      const bookingDate = new Date(booking.fromDate)
        .toISOString()
        .split("T")[0];
      const fromTime = formatTime(booking.fromTime);
      const toTime = formatTime(booking.toTime);

      return (
        bookingDate === formattedSelectedDate &&
        formattedTime > fromTime &&
        formattedTime < toTime // Block only if within booked range
      );
    });
  };

  const timeMenuForFrom = () => (
    <Menu
      style={{
        maxHeight: "200px",
        overflowY: "auto",
        width: "165px",
      }}
    >
      {timeSlots.map((time) => (
        <Menu.Item
          key={time}
          onClick={() => handleTimeSelect(time, "from")}
          disabled={isFromTimeBlocked(time)}
          style={{
            color: isFromTimeBlocked(time) ? "red" : "black",
            cursor: isFromTimeBlocked(time) ? "not-allowed" : "pointer",
          }}
        >
          {time}
        </Menu.Item>
      ))}
    </Menu>
  );

  const timeMenuForTo = () => (
    <Menu
      style={{
        maxHeight: "200px",
        overflowY: "auto",
        width: "165px",
      }}
    >
      {timeSlots.map((time) => (
        <Menu.Item
          key={time}
          onClick={() => handleTimeSelect(time, "to")}
          disabled={isToTimeBlocked(time)}
          style={{
            color: isToTimeBlocked(time) ? "red" : "black",
            cursor: isToTimeBlocked(time) ? "not-allowed" : "pointer",
          }}
        >
          {time}
        </Menu.Item>
      ))}
    </Menu>
  );

  const calculateHeight = (fromTime, toTime) => {
    const start = moment(fromTime, "HH:mm");
    const end = moment(toTime, "HH:mm");
    const duration = moment.duration(end.diff(start));
    return (duration.asMinutes() / 60) * 75.5;
  };

  const calculateTopOffset = (fromTime) => {
    const from = moment(fromTime, "HH:mm");
    return (from.hour() * 60 + from.minute()) * (78 / 60);
  };

  return (
    <Space direction="vertical" size={20} style={{ width: "100%" }}>
      <Row gutter={24}>
        <Col className="gutter-row" span={6}>
          <Card
            bordered={false}
            onClick={() => {
              navaigate("/employees");
            }}
            style={{
              cursor: "pointer",
              backgroundColor: "#26b689",
              height: "100px",
              width: "245px",
            }}
            loading={loading}
          >
            <Statistic
              prefix={<FiUsers style={{ fontSize: "20px", color: "white" }} />}
              precision={2}
              formatter={formatter}
              title={
                <>
                  <Row justify="space-between">
                    <Col style={{ color: "white", fontSize: "20px" }}>
                      <b> Employees</b>
                    </Col>
                    <Col>
                      <GoInfo style={{ fontSize: "20px", color: "white" }} />
                    </Col>
                  </Row>
                </>
              }
              valueStyle={{ fontWeight: 500, color: "white" }}
              value={statsData?.employees?.employeesCount}
            />
            {/* <Progress percent={99.99} showInfo={false} />
            <hr style={{ opacity: "25%" }}></hr>
            <Typography.Text>
              New employees this month{" "}
              {statsData?.employees?.employeesCountInPastMonth}
            </Typography.Text> */}
          </Card>
        </Col>

        <Col className="gutter-row" span={6}>
          <Card
            bordered={false}
            onClick={() => {
              navaigate("/guestEntry");
            }}
            style={{
              cursor: "pointer",
              backgroundColor: "#f4d338",
              height: "100px",
              width: "245px",
            }}
            loading={loading}
          >
            <Statistic
              prefix={
                <RiSurveyLine style={{ fontSize: "20px", color: "white" }} />
              }
              precision={2}
              formatter={formatter}
              title={
                <>
                  <Row justify="space-between">
                    <Col style={{ color: "white", fontSize: "20px" }}>
                      {" "}
                      <b> Guests </b>
                    </Col>
                    <Col>
                      <GoInfo style={{ fontSize: "20px", color: "white" }} />
                    </Col>
                  </Row>
                </>
              }
              valueStyle={{ fontWeight: 500, color: "white" }}
              value={statsData?.guests?.guestsCount}
            />

            {/* <Progress percent={99.99} showInfo={false} />
            <hr style={{ opacity: "25%" }}></hr>
            <Typography.Text>
              New surveys this month {statsData?.surveys?.upcomingSurveysCount}
            </Typography.Text> */}
          </Card>
        </Col>

        <Col className="gutter-row" span={6}>
          <Card
            bordered={false}
            onClick={() => {
              navaigate("/customers");
            }}
            style={{
              cursor: "pointer",
              backgroundColor: "#1b75bb",
              height: "100px",
              width: "245px",
            }}
            loading={loading}
          >
            <Statistic
              prefix={<LuUser style={{ fontSize: "20px", color: "white" }} />}
              precision={2}
              formatter={formatter}
              title={
                <>
                  <Row justify="space-between">
                    <Col style={{ color: "white", fontSize: "20px" }}>
                      {" "}
                      <b> Clients </b>{" "}
                    </Col>
                    <Col>
                      <GoInfo style={{ fontSize: "20px", color: "white" }} />
                    </Col>
                  </Row>
                </>
              }
              valueStyle={{ fontWeight: 500, color: "white" }}
              value={statsData?.clients?.clientsCount}
            />
            {/* <Progress percent={99.99} showInfo={false} />
            <hr style={{ opacity: "25%" }}></hr>
            <Typography.Text>
              New customer this month{" "}
              {statsData?.customers?.customersCountInPastMonth}
            </Typography.Text> */}

            {/* <Typography.Text>
              This month sales {data.customers.thisMonthSales}
            </Typography.Text> */}
          </Card>
        </Col>

        <Col className="gutter-row" span={6}>
          <Card
            bordered={false}
            onClick={() => {
              navaigate("/items/manage");
            }}
            style={{
              cursor: "pointer",
              backgroundColor: "#e23952",
              height: "100px",
              width: "245px",
            }}
            loading={loading}
          >
            <Statistic
              prefix={
                <MdOutlineProductionQuantityLimits
                  style={{ fontSize: "20px", color: "white" }}
                />
              }
              precision={2}
              formatter={formatter}
              title={
                <>
                  <Row justify="space-between">
                    <Col style={{ color: "white", fontSize: "20px" }}>
                      {" "}
                      <b> Seating Type </b>{" "}
                    </Col>
                    <Col>
                      <GoInfo style={{ fontSize: "20px", color: "white" }} />
                    </Col>
                  </Row>
                </>
              }
              valueStyle={{ fontWeight: 500, color: "white" }}
              value={statsData?.seats?.seatsCount}
            />
            {/* <Progress percent={99.99} showInfo={false} />
            <hr style={{ opacity: "25%" }}></hr>
            <Typography.Text>{`HSN Codes: ${statsData?.items?.hsnCodesCount}`}</Typography.Text> */}
          </Card>
        </Col>
      </Row>
      <Row gutter={16} justify="center">
        <Col span={12} style={{ marginTop: 16, textAlign: "center" }}>
          <Typography.Title
            level={4}
            style={{ fontSize: "16px", textAlign: "center" }}
          >
            Layout
          </Typography.Title>
          <Dropdown
            overlay={menu}
            trigger={["click"]}
            visible={dropdownVisible}
            onVisibleChange={toggleDropdown}
          >
            <Button
              onClick={toggleDropdown}
              style={{
                width: "100%",
                backgroundColor: "#f0f0f0",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "0 12px",
                height: "35px",
                fontSize: "15px",
                borderColor: "#d9d9d9",
              }}
            >
              {selectedMenuItem}{" "}
              {dropdownVisible ? (
                <UpOutlined />
              ) : (
                <DownOutlined style={{ marginTop: "2px" }} />
              )}
            </Button>
          </Dropdown>
        </Col>
      </Row>
      <Row gutter={10}>
        {/* <Col span={8}>
          <Card>
            {/* <Calendar fullscreen={false} onPanelChange={onPanelChangeLeft} /> */}

        {/* <div style={{ marginTop: 26 }}>
              <Title
                level={4}
                style={{
                  fontSize: "16px",
                  position: "relative",
                  cursor: "pointer",
                }}
                onClick={toggleCategoriesDropdown}
              >
                Categories
                <span
                  style={{
                    position: "absolute",
                    right: "37px",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                >
                  {categoriesDropdownVisible ? (
                    <UpOutlined
                      style={{
                        fontSize: "14px",
                        margin: "0px -22px 0px 30px",
                      }}
                    />
                  ) : (
                    <DownOutlined
                      style={{
                        fontSize: "14px",
                        margin: "0px -22px 0px 30px",
                      }}
                    />
                  )}
                </span>
              </Title>
              {categoriesDropdownVisible && (
                <div
                  style={{
                    padding: "10px",
                    borderRadius: "15px",
                    marginTop: "10px",
                  }}
                >
                  <Checkbox
                    style={{ fontSize: "17px" }}
                    checked={meetingRoomsChecked}
                    onChange={handleCheckboxChange}
                  >
                    Meeting rooms
                  </Checkbox>
                </div>
              )}
            </div> */}

        {/* <div
              style={{ marginTop: 10, display: "flex", alignItems: "center" }}
            >
              <Typography.Title level={4} style={{ fontSize: "16px", flex: 1 }}>
                Only mine
              </Typography.Title>
              <Switch onChange={onChange} checked={open} style={switchStyle} />
            </div> */}
        {/* </Card>
        </Col> */}
        <Col span={16}>
          {selectedMenuItem === "Day" && (
            <Card style={{ padding: "20px", width: "150%", margin: "auto" }}>
              <Row style={{ alignItems: "center", marginBottom: "20px" }}>
                <Col span={4} onClick={handleLeftArrowClick}>
                  <ArrowLeftOutlined
                    style={{ fontSize: "20px", cursor: "pointer" }}
                  />
                </Col>
                <Col span={16} style={{ textAlign: "center" }}>
                  <Typography.Text style={{ fontSize: "16px", color: "grey" }}>
                    {moment(currentDate).format("DD MMM YYYY")}
                  </Typography.Text>
                </Col>
                <Col span={4} onClick={handleRightArrowClick}>
                  <ArrowRightOutlined
                    style={{
                      fontSize: "20px",
                      cursor: "pointer",
                      marginLeft: "63px",
                    }}
                  />
                </Col>
              </Row>
              <div style={{ position: "relative" }}>
                {timeSlotsForCalendar.map((time, timeIndex) => {
                  const timeSlotBookings = bookings.filter((booking) => {
                    const bookingDate = moment(booking.fromDate);
                    return (
                      bookingDate.isSame(moment(currentDate), "day") &&
                      moment(booking.fromTime, "HH:mm").hour() ===
                        parseInt(time)
                    );
                  });

                  return (
                    <React.Fragment key={timeIndex}>
                      <Row
                        style={{
                          alignItems: "center",
                          marginBottom: "20px",
                          position: "relative",
                        }}
                        onMouseEnter={() => setHoveredTimeSlot(time)}
                        onMouseLeave={() => setHoveredTimeSlot(null)}
                      >
                        <Col span={4}>
                          <Typography.Text
                            style={{ fontSize: "12px", color: "grey" }}
                          >
                            {time}
                          </Typography.Text>
                        </Col>
                        <Col span={20} style={{ position: "relative" }}>
                          <div
                            style={{
                              height: "1px",
                              background: "#ccc",
                              width: "100%",
                            }}
                          />
                        </Col>
                      </Row>

                      {timeSlotBookings
                        .sort((a, b) =>
                          moment(a.fromTime, "HH:mm").diff(
                            moment(b.fromTime, "HH:mm")
                          )
                        )
                        .map((booking) => {
                          const bookingStyle = calculateBookingStyle(booking);
                          return (
                            <div
                              key={booking._id}
                              style={{
                                position: "absolute",
                                top: bookingStyle.top,
                                height: bookingStyle.height,
                                backgroundColor: "#f84f88",
                                width: "85%",
                                zIndex: 1,
                                cursor: "pointer",
                                padding: "10px",
                                left: bookingStyle.left,
                                overflow: "hidden",
                                borderRadius: "4px",
                              }}
                              onClick={() => handleRowClick(booking)}
                            >
                              <div
                                style={{
                                  color: "white",
                                  fontWeight: "700",
                                  fontSize: "11px",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {moment(booking.fromTime, "HH:mm").format(
                                  "HH:mm"
                                )}{" "}
                                -{" "}
                                {moment(booking.toTime, "HH:mm").format(
                                  "HH:mm"
                                )}{" "}
                                {booking.bookingName}
                                <div>
                                  Guests Can Join:{" "}
                                  {booking.guestsCanJoin ? "Yes" : "No"}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </React.Fragment>
                  );
                })}

                {/* Display `+` icon for empty slots */}
                {timeSlotsForCalendar.map((time, timeIndex) => {
                  const nextTime = timeSlotsForCalendar[timeIndex + 1];
                  if (!nextTime) return null;

                  const currentTime = moment(time, "HH:mm");
                  const nextSlotTime = moment(nextTime, "HH:mm");

                  // Check if the current time slot is completely or partially free
                  const isFreeSlot = !bookings.some((booking) => {
                    const bookingDate = moment(booking.fromDate);
                    const fromTime = moment(booking.fromTime, "HH:mm");
                    const toTime = moment(booking.toTime, "HH:mm");

                    return (
                      bookingDate.isSame(moment(currentDate), "day") &&
                      fromTime.isBefore(nextSlotTime) &&
                      toTime.isAfter(currentTime)
                    );
                  });

                  const isPartiallyFreeSlot = bookings.some((booking) => {
                    const bookingDate = moment(booking.fromDate);
                    const fromTime = moment(booking.fromTime, "HH:mm");
                    const toTime = moment(booking.toTime, "HH:mm");

                    return (
                      bookingDate.isSame(moment(currentDate), "day") &&
                      ((fromTime.isBefore(nextSlotTime) &&
                        fromTime.isAfter(currentTime)) ||
                        (toTime.isBefore(nextSlotTime) &&
                          toTime.isAfter(currentTime)))
                    );
                  });

                  // Display `+` icon if the slot is free or partially free
                  if (isFreeSlot || isPartiallyFreeSlot) {
                    return (
                      <div
                        key={timeIndex}
                        style={{
                          position: "absolute",
                          top: `calc(${timeIndex} * 42px)`,
                          height: "42px",
                          width: "85%",
                          zIndex: 1,
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "flex-end",
                          cursor: "pointer",
                          left: "80px",
                        }}
                        onMouseEnter={() => setHoveredSlot(time)}
                        onMouseLeave={() => setHoveredSlot(null)}
                        onClick={() =>
                          handleSlotClick(
                            moment(time, "HH:mm").format("HH:mm"),
                            moment(nextTime, "HH:mm").format("HH:mm")
                          )
                        }
                      >
                        {hoveredSlot === time && (
                          <PlusOutlined
                            style={{
                              fontSize: "16px",
                              color: "gray",
                              position: "absolute",
                              textAlign: "center",
                              left: "50%",
                            }}
                          />
                        )}
                      </div>
                    );
                  }

                  return null;
                })}
              </div>
            </Card>
          )}
          {selectedMenuItem === "Month" && (
            <Card style={{ width: "150%" }}>
              <Calendar
                onPanelChange={() => {}}
                dateCellRender={dateCellRender}
              />
            </Card>
          )}
          {selectedMenuItem === "Week" && (
            <Card style={{ width: "150%" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: 10,
                }}
              >
                <BiChevronLeft
                  onClick={handlePreviousAgenda}
                  style={{ fontSize: "2.5em", cursor: "pointer" }}
                />
                <div style={{ fontSize: "1.5em" }}>
                  {startDateAgenda.toLocaleString("default", { month: "long" })}{" "}
                  {startDateAgenda.getFullYear()}
                </div>
                <BiChevronRight
                  onClick={handleNextAgenda}
                  style={{ fontSize: "2.5em", cursor: "pointer" }}
                />
              </div>

              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex" }}>
                  {weekDays.map((weekday, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        border: "1px solid #ccc",
                        padding: "10px",
                        minWidth: "142px",
                        height: "80px",
                        boxSizing: "border-box",
                      }}
                    >
                      <div style={{ fontSize: "14px", marginBottom: "5px" }}>
                        {weekday}
                      </div>
                      <div
                        style={{
                          fontSize: "1.2em",
                          color:
                            weekDates[index].toDateString() ===
                            today.toDateString()
                              ? "azure"
                              : "black",
                          fontWeight: "bold",
                          borderRadius: "50%",
                          padding: "0px 10px 0px 10px",
                          backgroundColor:
                            weekDates[index].toDateString() ===
                            today.toDateString()
                              ? "rgb(27, 117, 187)"
                              : "transparent",
                        }}
                      >
                        {weekDates[index].getDate()}
                      </div>
                    </div>
                  ))}
                </div>

                <div style={{ display: "flex" }}>
                  {weekDays.map((weekday, index) => {
                    const date = weekDates[index].toDateString();
                    const bookingsForDay = bookings.filter(
                      (booking) =>
                        new Date(booking.fromDate).toDateString() === date ||
                        new Date(booking.toDate).toDateString() === date
                    );

                    return (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          border: "1px solid #ccc",
                          padding: "10px",
                          minWidth: "142px",
                          height: "400px",
                          backgroundColor: "#f1f2f6",
                          boxSizing: "border-box",
                          overflowY: "auto",
                          position: "relative", // Added for the positioning of the plus icon
                        }}
                      >
                        {bookingsForDay.length > 0 ? (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                              height: "100%",
                              overflowY: "auto",
                            }}
                          >
                            {bookingsForDay.map((booking, idx) => (
                              <div
                                key={idx}
                                onClick={() => {
                                  setSelectedBooking(booking);
                                  setShowBookingModal(true);
                                }}
                                style={{
                                  backgroundColor: "#f84f88",
                                  color: "white",
                                  borderRadius: "5px",
                                  padding: "5px",
                                  marginBottom: "5px",
                                  fontSize: "12px",
                                  textAlign: "left",
                                  cursor: "pointer",
                                }}
                              >
                                <div
                                  style={{
                                    fontSize: "11px",
                                    fontWeight: "700",
                                  }}
                                >
                                  {formatTime(booking.fromTime)} -{" "}
                                  {formatTime(booking.toTime)}
                                </div>
                                <div style={{ fontWeight: "500" }}>
                                  {booking.bookingName}
                                </div>
                                <div style={{ fontWeight: "500" }}>
                                  Guests Can Join:{" "}
                                  {booking.guestsCanJoin ? "Yes" : "No"}
                                </div>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              fontSize: "2em",
                              color: "rgb(27, 117, 187)",
                              cursor: "pointer",
                              zIndex: 10,
                            }}
                            onClick={() => handlePlusIconClickAdd(date)}
                          >
                            +
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </Card>
          )}
          {selectedMenuItem === "Agenda" && (
            <Card style={{ width: "150%" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: 10,
                }}
              >
                <BiChevronLeft
                  onClick={handlePreviousAgenda}
                  style={{ fontSize: "2.5em", cursor: "pointer" }}
                />
                <div style={{ fontSize: "1.5em" }}>
                  {startDateAgenda.toLocaleString("default", { month: "long" })}{" "}
                  {startDateAgenda.getFullYear()}
                </div>
                <BiChevronRight
                  onClick={handleNextAgenda}
                  style={{ fontSize: "2.5em", cursor: "pointer" }}
                />
              </div>
              <div style={{ display: "flex" }}>
                <div>
                  {weekDays.map((weekday, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        borderRight: "1px solid #ccc",
                        borderBottom: "1px solid #ccc",
                        borderLeft: "1px solid #ccc",
                        borderTop: "1px solid #ccc",
                        padding: "10px",
                        minWidth: "120px",
                        height: "120px", // Fixed height for cells
                        borderWidth: "1px 1px 1px 1px",
                        boxSizing: "border-box",
                        overflow: "hidden", // Ensure content does not overflow outside cell
                      }}
                    >
                      <div style={{ fontSize: "14px", marginBottom: "5px" }}>
                        {weekday}
                      </div>
                      <div
                        style={{
                          fontSize: "1.2em",
                          color:
                            weekDates[index].toDateString() ===
                            today.toDateString()
                              ? "azure"
                              : "black",
                          fontWeight: "bold",
                          borderRadius: "50%",
                          padding: "0px 10px 0px 9px",
                          backgroundColor:
                            weekDates[index].toDateString() ===
                            today.toDateString()
                              ? "rgb(27, 117, 187)"
                              : "transparent",
                        }}
                      >
                        {weekDates[index].getDate()}
                      </div>
                    </div>
                  ))}
                </div>
                <div style={{ flex: 1 }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {weekDays.map((weekday, index) => {
                      const date = weekDates[index].toDateString();
                      const bookingsForDay = bookings.filter(
                        (booking) =>
                          new Date(booking.fromDate).toDateString() === date ||
                          new Date(booking.toDate).toDateString() === date
                      );

                      return (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            borderRight: "1px solid #ccc",
                            borderBottom: "1px solid #ccc",
                            borderLeft: "1px solid #ccc",
                            borderTop: "1px solid #ccc",
                            padding: "10px",
                            minWidth: "200px",
                            height: "120px", // Fixed height for cells
                            backgroundColor: "#f1f2f6",
                            borderWidth: "1px 1px 1px 0px",
                            position: "relative",
                            boxSizing: "border-box",
                            overflowY: "auto", // Scroll if content exceeds
                          }}
                        >
                          {bookingsForDay.length > 0 && (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%", // Ensure full width
                                height: "100%", // Use full height of container
                                overflowY: "auto", // Add scroll if needed
                              }}
                            >
                              {bookingsForDay.map((booking, idx) => (
                                <div
                                  key={idx}
                                  onClick={() => {
                                    setSelectedBooking(booking);
                                    setShowBookingModal(true);
                                  }}
                                  style={{
                                    backgroundColor: "#f84f88",
                                    color: "white",
                                    borderRadius: "5px",
                                    padding: "5px",
                                    marginBottom: "5px",
                                    fontSize: "12px",
                                    textAlign: "left",
                                    cursor: "pointer",
                                  }}
                                >
                                  <div
                                    style={{
                                      fontSize: "11px",
                                      fontWeight: "700",
                                    }}
                                  >
                                    {formatTime(booking.fromTime)} -{" "}
                                    {formatTime(booking.toTime)}
                                  </div>
                                  <div style={{ fontWeight: "500" }}>
                                    {booking.bookingName}
                                  </div>
                                  <div style={{ fontWeight: "500" }}>
                                    Guests Can Join:{" "}
                                    {booking.guestsCanJoin ? "Yes" : "No"}
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </Card>
          )}
        </Col>
      </Row>
      <Button
        type="primary"
        shape="circle"
        size="large"
        icon={
          <BiPlus
            style={{
              fontSize: "36px",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
        }
        style={{
          position: "fixed",
          bottom: "50px",
          right: "30px",
          zIndex: 1000,
          height: "50px",
          width: "50px",
        }}
        onClick={showModal}
      />
      <Modal
        title={
          <div
            style={{
              textAlign: "center",
              fontWeight: "bold",
              backgroundColor: "rgb(226, 57, 82)",
              color: "#fff",
              margin: "-20px -24px 16px -24px",
              padding: "10px 0px 16px",
              borderRadius: "6px 3px 0px 0px",
              fontSize: "20px",
            }}
          >
            {!showFirstDiv && (
              <ArrowLeftOutlined
                style={{
                  position: "absolute",
                  left: "10px",
                  fontSize: "22px",
                  cursor: "pointer",
                  top: "15px",
                }}
                onClick={() => setShowFirstDiv(true)}
              />
            )}
            Book a Spot
          </div>
        }
        open={isModalVisible}
        onCancel={handleCancel}
        footer={[]}
        closeIcon={
          <span style={{ color: "white", fontSize: "30px", marginTop: "-6px" }}>
            &times;
          </span>
        }
      >
        <Form layout="vertical">
          {showFirstDiv ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                backgroundColor: "gainsboro",
                borderRadius: "10px",
                cursor: "pointer",
              }}
              onClick={() => setShowFirstDiv(false)}
            >
              <h3
                style={{
                  marginBottom: "8px",
                  fontSize: "18px",
                  margin: "0px 0px 10px 11px",
                  padding: "10px 0px 0px 0px",
                }}
              >
                Meeting Rooms
              </h3>
              <BiChevronRight
                style={{ marginRight: "10px", fontSize: "40px" }}
              />
            </div>
          ) : (
            <div
              style={{
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <BiChevronLeft
                  onClick={handlePrev}
                  style={{ fontSize: "30px", cursor: "pointer" }}
                />
                <h3
                  style={{
                    marginBottom: "8px",
                    fontSize: "18px",
                    padding: "10px 0px 0px 0px",
                  }}
                >
                  {currentStartDate.format("MMMM YYYY")}
                </h3>
                <BiChevronRight
                  onClick={handleNext}
                  style={{ fontSize: "30px", cursor: "pointer" }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                {[...Array(7)].map((_, index) => {
                  const day = currentStartDate
                    .clone()
                    .startOf("week")
                    .add(index, "days");

                  const isToday = day.isSame(currentDate, "day");
                  return (
                    <div
                      key={index}
                      style={{
                        width: "calc(100% / 7)",
                        textAlign: "center",
                        position: "relative",
                      }}
                    >
                      <div
                        style={{
                          color: "#737b7e",
                          textTransform: "none",
                          fontSize: "15px",
                        }}
                      >
                        {day.format("ddd")}
                      </div>
                      <div
                        style={{
                          marginTop: "10px",
                          display: "inline-block",
                          width: "24px",
                          height: "24px",
                          borderRadius: "50%",
                          backgroundColor: isToday
                            ? "rgb(226, 57, 82)"
                            : "transparent",
                          color: isToday ? "white" : "inherit",
                          textAlign: "center",
                          lineHeight: "24px",
                          fontSize: "14px",
                          marginBottom: "5px",
                        }}
                      >
                        {day.format("D")}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "20px",
                  padding: "10px",
                  backgroundColor: "#f0f0f0",
                  borderRadius: "10px",
                }}
                onClick={showConferenceModal}
              >
                <div style={{ flex: "0 0 auto", marginRight: "10px" }}>
                  <BankOutlined style={{ fontSize: "50px", color: "grey" }} />
                </div>
                <div style={{ flex: "1 1 auto" }}>
                  <h3 style={{ marginBottom: "5px" }}>Conference Room</h3>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span
                      style={{
                        display: "inline-block",
                        width: "10px",
                        height: "10px",
                        backgroundColor: "green",
                        borderRadius: "50%",
                        marginRight: "5px",
                        flexShrink: 0,
                      }}
                    ></span>
                    <p
                      style={{
                        marginBottom: "0",
                        flexGrow: 1,
                      }}
                    >
                      Available from: 8:00
                    </p>
                  </div>
                </div>
                <div style={{ flex: "0 0 auto" }}>
                  <BiChevronRight
                    style={{ fontSize: "30px", cursor: "pointer" }}
                  />
                </div>
              </div>
            </div>
          )}
        </Form>
      </Modal>
      {/* for plus icon to add booking */}
      <Modal
        title={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
              backgroundColor: "rgb(226, 57, 82)",
              padding: "17px 0px 12px 0px",
              margin: "-20px -24px 0px -24px",
              borderRadius: "7px 7px 0px 0px",
            }}
          >
            <ArrowLeftOutlined
              onClick={showFirstModal}
              style={{
                position: "absolute",
                left: "16px",
                color: "white",
                cursor: "pointer",
              }}
            />
            <span
              style={{
                flexGrow: 1,
                textAlign: "center",
                fontSize: "16px",
                color: "white",
              }}
            >
              Conference Room Booking
            </span>
          </div>
        }
        open={isConferenceModalVisible}
        onCancel={handleCancelConference}
        footer={null}
        closeIcon={
          <span style={{ color: "white", fontSize: "25px", marginTop: "-6px" }}>
            &times;
          </span>
        }
        bodyStyle={{ padding: "16px" }}
        style={{ borderRadius: "8px" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "16px",
          }}
        >
          <BiChevronLeft
            onClick={handlePreviousDateRange}
            style={{
              fontSize: "30px",
              cursor: "pointer",
            }}
          />
          <div>
            <span style={{ fontWeight: "bold", fontSize: "17px" }}>
              {selectedDate
                ? formatDateForSelectedDate(selectedDate)
                : formatDateForSelectedDate(currentDate)}
            </span>
          </div>
          <BiChevronRight
            onClick={handleNextDateRange}
            style={{
              fontSize: "30px",
              cursor: "pointer",
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            marginTop: "8px",
            padding: "0px 16px",
            justifyContent: "space-around",
          }}
        >
          {generateWeekDates(currentDate).map((date, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                cursor: "pointer",
              }}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
              onClick={() => handleDateClick(date, index)}
            >
              <span
                style={{
                  fontSize: "16px",
                  color: "#737b7e",
                  textTransform: "none",
                }}
              >
                {date.format("ddd")}
              </span>
              <div
                style={{
                  marginTop: "8px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "26px",
                  height: "26px",
                  borderRadius: "50%",
                  position: "relative",
                  backgroundColor:
                    selectedDate === date.format("DD MMM YYYY")
                      ? "rgb(226, 57, 82)"
                      : "transparent",
                  border:
                    hoveredIndex === index
                      ? "2px solid rgb(226, 57, 82)"
                      : "none",
                }}
              >
                <span
                  style={{
                    fontSize: "15px",
                    position: "relative",
                    color:
                      selectedDate === date.format("DD MMM YYYY")
                        ? "white"
                        : "",
                  }}
                >
                  {date.format("D")}
                </span>
              </div>
            </div>
          ))}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginRight: "20px",
            height: "240px",
            overflowY: "scroll",
            marginTop: "16px",
          }}
        >
          {generateTimeSlots().map((time, index) => {
            const bookingsForSelectedDate = getBookingsForDate(selectedDate);
            const timeSlotStart = moment(time, "HH:mm");
            const timeSlotEnd = timeSlotStart.clone().add(1, "hour");

            // Define half-hour segments within the time slot
            const halfSlot1Start = timeSlotStart;
            const halfSlot1End = halfSlot1Start.clone().add(30, "minutes");
            const halfSlot2Start = halfSlot1End;
            const halfSlot2End = timeSlotEnd;

            // Check for bookings overlapping with each half-slot
            const halfSlot1HasBooking = bookingsForSelectedDate.some(
              (booking) => {
                const bookingStart = moment(booking.fromTime, "HH:mm");
                const bookingEnd = moment(booking.toTime, "HH:mm");
                return (
                  (bookingStart.isBefore(halfSlot1End) &&
                    bookingEnd.isAfter(halfSlot1Start)) ||
                  bookingStart.isSame(halfSlot1Start)
                );
              }
            );

            const halfSlot2HasBooking = bookingsForSelectedDate.some(
              (booking) => {
                const bookingStart = moment(booking.fromTime, "HH:mm");
                const bookingEnd = moment(booking.toTime, "HH:mm");
                return (
                  (bookingStart.isBefore(halfSlot2End) &&
                    bookingEnd.isAfter(halfSlot2Start)) ||
                  bookingStart.isSame(halfSlot2Start)
                );
              }
            );

            return (
              <div
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "50px",
                  position: "relative",
                }}
              >
                <span style={{ fontSize: "17px", marginRight: "8px" }}>
                  {time}
                </span>
                <div
                  style={{
                    flex: 1,
                    backgroundColor: "#f0f0f0",
                    height: "1px",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "1px",
                      backgroundColor: "#ccc",
                      position: "absolute",
                      top: "50%",
                      left: 0,
                    }}
                  ></div>
                  {bookingsForSelectedDate.map((booking, bookingIndex) => {
                    const bookingStart = moment(booking.fromTime, "HH:mm");
                    const bookingEnd = moment(booking.toTime, "HH:mm");
                    const overlapsCurrentSlot =
                      (bookingStart.isBefore(timeSlotEnd) &&
                        bookingEnd.isAfter(timeSlotStart)) ||
                      bookingStart.isSame(timeSlotStart);

                    if (!overlapsCurrentSlot) return null;

                    return (
                      <div
                        key={bookingIndex}
                        style={{
                          width: "90%",
                          height: `${calculateHeight(
                            booking.fromTime,
                            booking.toTime
                          )}px`,
                          backgroundColor: "#f84f88",
                          position: "absolute",
                          top: `${
                            calculateTopOffset(booking.fromTime) - index * 78
                          }px`,
                          left: "55%",
                          transform: "translateX(-50%)",
                          padding: "5px",
                          borderRadius: "5px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => handleRowClick(booking)}
                      >
                        <span
                          style={{
                            fontSize: "15px",
                            color: "white",
                            marginTop: "-45px",
                          }}
                        >
                          {`${bookingStart.format(
                            "H:mm"
                          )} - ${bookingEnd.format("H:mm")} ${
                            booking.bookingName
                          }`}
                        </span>
                      </div>
                    );
                  })}
                  {!(halfSlot1HasBooking && halfSlot2HasBooking) && (
                    <span
                      style={{
                        width: "33px",
                        height: "33px",
                        position: "absolute",
                        left: "50%",
                        cursor: "pointer",
                        zIndex: 1,
                        borderRadius: "17px",
                        backgroundColor: "gainsboro",
                        top: "23px",
                        marginLeft: "-19px",
                      }}
                      onClick={() => handleAddTimeSlot(time)}
                    >
                      <PlusOutlined
                        style={{
                          fontSize: "20px",
                          color: "#f84f88",
                          // margin: "6px 0px 0px 7px",
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                      />
                    </span>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </Modal>
      {/* booking form */}
      <Modal
        title={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "rgb(226, 57, 82)",
              margin: "-20px -24px 0px -24px",
              borderRadius: "7px 8px 0px 0px",
              padding: "15px 0px 15px 0px",
            }}
          >
            <ArrowLeftOutlined
              onClick={conferenceModalShow}
              style={{ marginRight: 163, marginLeft: 10, color: "white" }}
            />
            <Title level={4} style={{ margin: 0, color: "white" }}>
              Conference Room
            </Title>
          </div>
        }
        closeIcon={
          <span style={{ color: "white", fontSize: "25px", marginTop: "-1px" }}>
            &times;
          </span>
        }
        open={isAddModalVisible}
        onOk={handleAddModalOk}
        onCancel={handleAddModalCancel}
        footer={null}
      >
        <div
          style={{ maxHeight: "60vh", overflowY: "auto", overflowX: "hidden" }}
        >
          <Form
            form={form}
            layout="vertical"
            style={{ marginTop: "20px" }}
            onFinish={onFinish}
            initialValues={{
              spot: "Conference Room",
              fromDate: moment(
                `${selectedDate} ${selectedTimeFrom}`,
                "DD MMM YYYY HH:mm"
              ),
              toDate: moment(
                `${selectedDate} ${selectedTimeTo}`,
                "DD MMM YYYY HH:mm"
              ),
            }}
          >
            <Row gutter={16} align="middle">
              <Col span={6}>
                <Form.Item
                  label={<span style={{ fontSize: "17px" }}>Spot</span>}
                  name="spot"
                  style={{ marginBottom: 0, marginTop: 6 }}
                  initialValue="Conference Room"
                >
                  <Input type="hidden" value="Conference Room" />
                </Form.Item>
              </Col>
              <Col span={18}>
                <Form.Item style={{ marginBottom: 0 }}>
                  <div
                    style={{
                      backgroundColor: "lightgray",
                      width: "100%",
                      padding: "10px",
                      display: "flex",
                      alignItems: "center",
                      position: "relative",
                      borderRadius: "10px",
                      top: "-13px",
                      right: "10px",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#f84f88",
                        width: "20px",
                        height: "20px",
                        borderRadius: "50%",
                        marginRight: "10px",
                      }}
                    />
                    <span>Conference Room</span>
                  </div>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} align="middle" style={{ marginTop: -19 }}>
              <Col span={6}>
                <Form.Item
                  label={<span style={{ fontSize: "16px" }}>From</span>}
                  name="fromDate"
                  style={{ marginBottom: 0, marginTop: 21 }}
                  // initialValue={`${selectedDate} ${selectedTimeFrom}`}
                >
                  <Input
                    type="hidden"
                    value={selectedTimeFrom || "Select Time"}
                    readOnly
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      form.setFieldsValue({
                        fromDate: `${selectedDate} ${selectedTimeFrom}`,
                      })
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={18} style={{ margin: "-66px 0px 21px 112px" }}>
                <Row gutter={16} align="middle">
                  <Col span={12}>
                    <div
                      style={{
                        backgroundColor: "lightgray",
                        padding: "10px",
                        borderRadius: "10px",
                        marginTop: "-13px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>{selectedDate}</span>
                      <CalendarOutlined
                        style={{ cursor: "pointer" }}
                        onClick={handleConferenceModalOpen}
                      />
                    </div>
                  </Col>
                  <Col span={12}>
                    <Dropdown
                      overlay={timeMenuForFrom("from")}
                      trigger={["click"]}
                    >
                      <div
                        style={{
                          backgroundColor: "lightgray",
                          padding: "10px",
                          borderRadius: "10px",
                          marginTop: "-13px",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <span>{selectedTimeFrom}</span>
                        <CaretDownOutlined />
                      </div>
                    </Dropdown>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row gutter={16} align="middle" style={{ marginTop: -19 }}>
              <Col span={6}>
                <Form.Item
                  label={<span style={{ fontSize: "16px" }}>To</span>}
                  name="toDate"
                  style={{ marginBottom: 0, marginTop: 21 }}
                  // initialValue={`${selectedDate} ${selectedTimeTo}`}
                >
                  <Input
                    type="hidden"
                    value={selectedTimeTo || "Select Time"}
                    readOnly
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      form.setFieldsValue({
                        toDate: `${selectedDate} ${selectedTimeTo}`,
                      })
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={18} style={{ margin: "-66px 0px 21px 112px" }}>
                <Row gutter={16} align="middle">
                  <Col span={12}>
                    <div
                      style={{
                        backgroundColor: "lightgray",
                        padding: "10px",
                        borderRadius: "10px",
                        marginTop: "-13px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>{selectedDate}</span>
                      <CalendarOutlined
                        style={{ cursor: "pointer" }}
                        onClick={handleConferenceModalOpen}
                      />
                    </div>
                  </Col>
                  <Col span={12}>
                    <Dropdown overlay={timeMenuForTo("to")} trigger={["click"]}>
                      <div
                        style={{
                          backgroundColor: "lightgray",
                          padding: "10px",
                          borderRadius: "10px",
                          marginTop: "-13px",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <span>{selectedTimeTo || "Select Time"}</span>
                        <CaretDownOutlined />
                      </div>
                    </Dropdown>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={16} align="middle">
              <Col span={24}>
                <Form.Item
                  label={<span style={{ fontSize: "16px" }}>Booking name</span>}
                  name="bookingName"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the booking name",
                    },
                    {
                      pattern: /^[a-zA-Z\s'-]+$/,
                      message:
                        "Invalid booking name. Please enter alphabetic letters.",
                    },
                  ]}
                  style={{ marginBottom: 24, fontSize: 16, marginTop: -15 }}
                >
                  <Input
                    placeholder="Enter booking name"
                    style={{ width: 458 }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} align="middle">
              <Col span={24}>
                <Form.Item
                  label={<span style={{ fontSize: "16px" }}>Note</span>}
                  name="note"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the note",
                    },
                  ]}
                  style={{ marginBottom: 32, fontSize: 16, marginTop: -15 }}
                >
                  <Input.TextArea
                    placeholder="Enter note"
                    style={{ width: 458 }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} align="middle">
              <Col span={24}>
                <Form.Item
                  label={
                    <span style={{ fontSize: "16px" }}>Guests can join</span>
                  }
                  name="guestsCanJoin"
                  valuePropName="checked"
                >
                  <Switch
                    style={{
                      transform: "scale(1.3)",
                      margin: "-69px 0px 0px 125px",
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row
              gutter={16}
              align="middle"
              justify="center"
              style={{ marginTop: "-25px" }}
            >
              <Col>
                <Form.Item>
                  <Button
                    htmlType="submit"
                    style={{
                      backgroundColor: "rgb(226, 57, 82)",
                      borderRadius: "10px",
                      width: "150px",
                      height: "40px",
                      border: "none",
                      fontSize: "16px",
                      fontWeight: 500,
                      color: "white",
                    }}
                  >
                    Book Now
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>
      {/* for edit detail RT */}
      <Modal
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0 16px",
            }}
          >
            <div></div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "0px -4px 0px 0px",
              }}
            >
              <Tooltip title="Edit">
                <EditOutlined
                  onClick={() => handleEdit(selectedBooking?._id)}
                  style={{
                    fontSize: "16px",
                    marginRight: "8px",
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
              <Tooltip title="Delete">
                <DeleteOutlined
                  onClick={showDeleteConfirmation}
                  style={{
                    fontSize: "16px",
                    marginRight: "8px",
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
            </div>
          </div>
        }
        open={showBookingModal}
        onCancel={() => setShowBookingModal(false)}
        footer={null}
      >
        {selectedBooking && (
          <div>
            <div>
              <h2>{selectedBooking.bookingName}</h2>
            </div>
            <div style={{ marginTop: "15px" }}>
              <span style={{ margin: "0px 26px 0px 0px" }}>
                <img
                  src=" data:image/svg+xml;charset=utf-8,%3Csvg
                xmlns='http://www.w3.org/2000/svg' width='18' height='18'
                viewBox='0 0 24 24'%3E%3Cpath d='M9 10v2H7v-2h2m4 0v2h-2v-2h2m4
                0v2h-2v-2h2m2-7a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0
                1-2-2V5a2 2 0 0 1 2-2h1V1h2v2h8V1h2v2h1m0 16V8H5v11h14M9
                14v2H7v-2h2m4 0v2h-2v-2h2m4 0v2h-2v-2h2Z'/%3E%3C/svg%3E"
                  alt="calander-img"
                />
              </span>
              &nbsp;
              <span style={{ fontSize: "15px" }}>{formattedDateTime}</span>
            </div>
            <div>
              <strong>
                <span
                  style={{
                    display: "inline-block",
                    width: "15px",
                    height: "15px",
                    borderRadius: "50%",
                    backgroundColor: "rgb(226, 57, 82)",
                    margin: "9px 26px 0px 1px",
                  }}
                ></span>
              </strong>
              &nbsp; Conference Room
            </div>
            <p style={{ marginTop: "10px" }}>
              <span
                style={{
                  textAlign: "center",
                  margin: "0px 0px 0px 2px",
                }}
              >
                {selectedBooking.note}
              </span>
            </p>
            <p style={{ marginTop: "10px" }}>
              <span
                style={{
                  display: "inline-block",
                  width: "25px",
                  height: "25px",
                  borderRadius: "50%",
                  backgroundColor: "#daf6d6",
                  color: "#2f9320",
                  textAlign: "center",
                  lineHeight: "26px",
                  margin: "0px 28px 0px -1px",
                  // fontWeight: "bold",
                }}
              >
                RT
              </span>
              <span style={{ fontWeight: "400" }}>Mr. Rahul Tundurkar</span>
            </p>
          </div>
        )}
      </Modal>
      {/* for delete */}
      <Modal
        title="Confirm Deletion"
        open={showDeleteModal}
        onOk={handleDelete}
        onCancel={() => setShowDeleteModal(false)}
        footer={
          <div
            style={{
              textAlign: "end",
              background: "transparent",
            }}
          >
            <Button
              type="primary"
              success
              onClick={() => setShowDeleteModal(false)}
            >
              Cancel
            </Button>
            <Button
              onClick={handleDelete}
              type="primary"
              danger
              style={{ marginLeft: "8px" }}
            >
              Delete
            </Button>
          </div>
        }
      >
        <p>
          Are you sure you want to delete this booking? This action cannot be
          undone.
        </p>
      </Modal>
    </Space>
  );
};

export default Dashboard;
